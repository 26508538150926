import { Suspense, lazy, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer, ToastContentProps } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import './assets/css/global.css';
import './assets/css/styles.css';

import $JQuery from 'jquery'
import 'popper.js';
import 'bootstrap';

import './i18n/index';

import Constant from './plugins/constant';
import Common from './plugins/common';
import API from './plugins/api';
import APIAction from './plugins/api_action';

const Routers = lazy(() => import('./routes/index'))
const Loading = lazy(() => import('./components/loading'))

declare global {
  interface Window {
    $: any,
    $t: any,
    $const: any,
    $common: any,
    $api: any,
    $api_action: any,
    $show_loading: () => {},
    $hide_loading: () => {},
    $notify_error: (content: string, handleCloseToast: Function) => {}
  }

  interface FileUploadInterface {
    id: string,
    type: string,
    file: File,
    status: number
  }

  interface ModalDataInterface {
    item: any,
    handleCallback: Function
  }

  interface ModalConfirmInterface {
    title: string,
    content: string,
    handleCancel: Function,
    handleOk: Function
  }
}

type ErrorNotificationProps = ToastContentProps<{
  title: string;
  content: string;
  closeToast: Function;
}>;
  
function ErrorNotification({
  data
}: ErrorNotificationProps) {
  return (
    <div>
      <div>
        <p className="mb-0">{data.content}</p>
        <a className="font-weight-bold text-primary-app" href="javascript:void(0)" onClick={() => data.closeToast()}>{window.$t('msg_send_email_again')}</a>
      </div>
    </div>
  );
}

function App() {
  const { t } = useTranslation()
  
  useLayoutEffect(() => {
    window.$notify_error = async (content, handleCloseToast) => notifyError(content, handleCloseToast)
  }, [])

  if (!window) {
    return <div></div>;
  }
  window.$ = $JQuery;
  window.$t = t;
  window.$const = Constant;
  window.$common = Common;
  window.$api = API;
  window.$api_action = APIAction;

  const notifyError = (content: string, handleCloseToast: Function) => {
    toast.error(ErrorNotification, {
      data: {
        title: '',
        content,
        closeToast: () => handleCloseToast()
      },
    });
  };

  if (typeof window === 'undefined') {
    return <div></div>;
  }
  return (
    <div className="App">
      <Suspense>
        <BrowserRouter>
          <Routers></Routers>
        </BrowserRouter>
      </Suspense>
      <Loading></Loading>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
    </div>
  )
}

export default App;
