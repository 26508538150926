const Constant = {
  EXCLUDE_FOOTER: ['/new-offer', '/offer-detail', '/offer-confirmation', '/offer-result', '/buyer-dashboard'],
  ROLE: {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    AGENT: 'case_agent',
    BUYER: 'buyer'
  },
  AUTHEN_STORE: 'authen_store',
  OFFER_STORE: 'offer_store',
  ADMIN_PATHS: ['/admin-dashboard', '/vendor-profile'],
  AGENT_PATHS: ['/offer-detail', '/docusign', '/agent-dashboard'],
  BUYER_PATHS: ['/search', '/search-result', '/new-offer', '/offer-detail', '/offer-confirmation', '/offer-payment', '/offer-package', '/offer-result', '/buyer-dashboard'],
  PHONE_MIN_LENGTH: 10,
  PHONE_MAX_LENGTH: 11,
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 12,
  MAX_FILE_UPLOADED: 10,
  MAX_FILE_SIZE: 100,
  FILE_TYPES: ['png', 'jpeg', 'jpg', 'pdf'],
  ERROR_CODE: {
    AUTH_EMAIL_NOT_VERIFIED: 'AUTH_EMAIL_NOT_VERIFIED'
  },
  OFFER_STATUS: {
    DRAFT: 'draft',
    SUBMITTED: 'submitted',
    AGREEMENT_SIGNED: 'agreement_signed',
    PAID: 'paid',
    REJECTED: 'rejected',
    APPROVED: 'approved',
    ACCEPTED: 'accepted',
    CANCELLED: 'cancelled',
    CLAIMED: 'claimed',
    FINALIZED: 'finalized',
    SIGNED: 'signed',
    REVOKED: 'revoked'
  },
  DATE_FORMAT: 'YYYY/MM/dd',
  SETTING_KEYS: {
    OFFER_PRICE: 'offer_price',
    STRIPE_PUBLISHABLE_KEY: 'stripe_publishable_key',
  },
  INCLUDED_ITEMS: [
    'Stove(s), oven(s), stove/oven combo(s)',
    'Refrigerator(s)',
    'Wine Refrigerator(s)',
    'Washer(s)',
    'Dryer(s)',
    'Dishwasher(s)',
    'Microwave(s)',
    'Video doorbell(s)',
    'Security camera equipment',
    'Security system(s)/alarm(s), other than separate video doorbell and camera equipment',
    'Smart home control devices',
    'Wall mounted brackets for video or audio equipment',
    'Above-ground pool(s)',
    'Spa(s)',
    'Bathroom mirrors',
    'Electric car charging systems and stations',
    'Potted trees/shrubs'
  ],
  TERMS_URL: 'https://www.offeright.com/terms-and-conditions',
  PRIVACY_URL: 'https://www.offeright.com/privacy-policy'
}

export default Constant;