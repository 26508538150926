const VERSION_1 = 'v1'

const APIAction = {
  // Authentication
  AUTH_REGISTER: `${VERSION_1}/auth/register`,
  AUTH_LOGIN: `${VERSION_1}/auth/login`,
  AUTH_FORGOT_PASSWORD: `${VERSION_1}/auth/forgot-password`,
  AUTH_RESET_PASSWORD: `${VERSION_1}/auth/reset-password`,
  AUTH_VERIFY_EMAIL: `${VERSION_1}/auth/verify-email`,
  AUTH_ACTIVATE_ACCOUNT: `${VERSION_1}/auth/activate-account`,
  AUTH_RESEND_CODE: `${VERSION_1}/auth/resend-verification-email`,
  // Users
  GET_USER_PROFILE: `${VERSION_1}/users/profile`,
  UPDATE_USER_PROFILE: `${VERSION_1}/users`,
  CHANGE_USER_PWD: `${VERSION_1}/users/change-password`,
  GET_USER_CONFIG: `${VERSION_1}/users/configs`,
  // Properties
  SEARCH_PROPERTY: `${VERSION_1}/properties`,
  // Offers
  OFFER: `${VERSION_1}/offers`,
  // Buyer
  BUYER_UPLOAD_DOC_URL: `${VERSION_1}/buyer/offers/upload-doc-url`,
  BUYER_OFFER: `${VERSION_1}/buyer/offers`,
  OFFER_PRICE: `${VERSION_1}/buyer/offers/offer-price`,

  // Agent
  CASE_AGENT_OFFER: `${VERSION_1}/case-agent/offers`,
  CASE_AGENT_CLAIM_OFFER: `${VERSION_1}/case-agent/offers/claim-offer`,
  CASE_AGENT_CONTRACT: `${VERSION_1}/case-agent/offers/my-contracts`,
  // Admin
  ADMIN_BUYERS: `${VERSION_1}/admin/users/buyers`,
  ADMIN_CHANGE_STATUS_BUYER: `${VERSION_1}/admin/users/buyers/status`,
  ADMIN_VENDORS: `${VERSION_1}/admin/users/vendors`,
  ADMIN_CASE_AGENTS: `${VERSION_1}/admin/users/case-agents`,
  ADMIN_MORTGAGE_LEADS: `${VERSION_1}/admin/offers/leads`,
  ADMIN_DASHBOARD: `${VERSION_1}/admin/dashboard/stats`,
  ADMIN_COUPON: `${VERSION_1}/admin/coupon-codes`,
  ADMIN_SETTINGS: `${VERSION_1}/admin/settings`
}

export default APIAction;