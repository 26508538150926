import reducer from './reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  isLoadPage: reducer.setIsLoadPage,
  isAuthenticated: reducer.setIsAuthenticated,
  userInfo: reducer.setUserInfo
})

export default rootReducer;
