import {
  SET_IS_LOAD_PAGE,
  SET_IS_AUTHENTICATED,
  SET_USER_INFO
} from './action';

export default {
  setIsLoadPage: (state = { value: {} }, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case SET_IS_LOAD_PAGE:
        return { ...state, value: action.payload }
      default:
        return { ...state, value: false }
    }
  },
  setIsAuthenticated: (state = { value: {} }, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case SET_IS_AUTHENTICATED:
        return { ...state, value: action.payload }
      default:
        return { ...state, value: false }
    }
  },
  setUserInfo: (state = { value: {} }, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case SET_USER_INFO:
        return { ...state, value: action.payload }
      default:
        return { ...state }
    }
  }
}
