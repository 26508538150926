import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import states from '../assets/json/states.json'
import { NavigateFunction } from 'react-router-dom';

const Common = {
  showErrorMsg (msg:string) {
    toast.dismiss()
    toast.error(msg)
  },
  showSuccessMsg (msg:string) {
    toast.dismiss()
    return;
    toast.success(msg)
  },
  showInfoMsg (msg:string) {
    toast.dismiss()
    toast.info(msg)
  },
  checkMobileWeb () {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    return isMobile
  },
  handleHeaderAPI (axios: any) {
    const cachedUser = window.$common.getCacheLocalStorage(window.$const.AUTHEN_STORE)
    if (cachedUser && !cachedUser.expired) {
      const user = cachedUser.data;
      if (user && user.access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`
      } 
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  },
  handleErrorAPI (error: any) {
    const dataError = error && error.response && error.response.data
    const ingorePaths = ['v1/properties']
    const url = error.config && error.config.url
    if (ingorePaths.includes(url)) {
      return
    }
    if (dataError && (dataError.message || dataError.errors)) {
      let message = ''
      if (dataError.response_code) {
        message = window.$t(`errors.${dataError.response_code}`)
      }
      if (message && message.indexOf('errors.') > -1) {
        if (typeof dataError.message === 'string') {
          message = dataError.message
        } else if (dataError.message && dataError.message.length > 0) {
          if (typeof dataError.message[0] === 'string') {
            message = dataError.message[0]
          } else {
            message = dataError.message[0][0]
          }
        } else if (dataError.errors && dataError.errors.length > 0) {
          if (typeof dataError.errors[0] === 'string') {
            message = dataError.errors[0]
          } else {
            message = dataError.errors[0][0]
          }
        }
      }
      window.$common.showErrorMsg(message)
    }
  },
  checkValue: (value: any) => {
    if (value === null || value === undefined || value === '' || value === 'null' || value === 'undefined') {
      return false;
    }
    return true;
  },
  getValueFromAttr (item: any, attr: string, defaultValue = null) {
    if (!this.checkValue(item) || !this.checkValue(attr)) {
      return defaultValue !== undefined ? defaultValue : null;
    }
    if (attr.indexOf('.') > -1) {
      const arrs = attr.split('.');
      let result = item;
      for (let i = 0; i < arrs.length; i++) {
        result = result[arrs[i]];
        if (!this.checkValue(result)) {
          return defaultValue !== undefined ? defaultValue : null;
        }
      }
      return result;
    }
    if (!this.checkValue(item[attr])) {
      return defaultValue !== undefined ? defaultValue : null;
    }
    return item[attr];
  },
  checkErrorValidation (errors: { [x: string]: any; }) {
    const attrs = Object.keys(errors);
    for (let i = 0; i < attrs.length; i++) {
      const attr = attrs[i];
      if (this.checkValue(errors[attr])) {
        return false;
      }
    }
    return true;
  },
  checkFormValidation (id: string, event: any) {
    const form = document.getElementById(id) as HTMLFormElement;
    if (!form) {
      return;
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      for (let i = 0; i < form.length; i++) {
        const element = form[i] as HTMLInputElement;
        if (element.required) {
          if (element.value === '' || element.className.indexOf('is-invalid-custom') > -1) {
            element.focus();
            break;
          }
        }
      }
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  },
  resetFormValidation (id: string) {
    const form = document.getElementById(id) as HTMLFormElement;
    form?.classList.remove('was-validated');
  },
  showModal (id: string) {
    window.$('.was-validated').removeClass('was-validated');
    window.$('.is-valid').removeClass('is-valid');
    window.$('.is-invalid').removeClass('is-invalid');
    window.$(`#${id}`).modal({ backdrop: 'static' });
  },
  hideModal (id: string) {
    window.$(`#${id}`).modal('hide');
  },
  validNumberic (value: string) {
    const result = /^\d+$/;
    return result.test(value);
  },
  validZipcode (value: string) {
    const result = /^[0-9,]+$/;
    return result.test(value);
  },
  validPhone (phone: string) {
    const result = /^\d+$/;
    return result.test(phone);
  },
  validEmail (email: string) {
    const result = /^\w+([\\.+_-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,16})+$/;
    return result.test(email);
  },
  validAlphaAndNumber (value: string) {
    const pattern = /[a-zåäö ]/i;
    return pattern.test(value);
  },
  validPassword (value: string) {
    const passwordValidation = [
      {
        status: 0,
        regex: /[A-Z]/
      },
      {
        status: 0,
        regex: /[a-z]/
      },
      {
        status: 0,
        regex: /[^a-zA-Z\d\s:]/
      }
    ];
    let count = 0;
    passwordValidation.forEach(rule => {
      if (rule.regex.test(value)) {
        rule.status = 1;
        count++;
      }
    });
    return count === 3;
  },
  getArrsInArrs(value: any, data: any, attr: string) {
    if (!this.checkValue(data) || !this.checkValue(attr)) {
      return [];
    }
    const arrs = data.filter(function (item: any) {
      return window.$common.getValueFromAttr(item, attr) === value;
    });
    return arrs;
  },
  getObjectInArrs(value: any, data: any, attr: string) {
    const arrs = window.$common.getArrsInArrs(value, data, attr);
    if (arrs.length > 0) {
      return arrs[0];
    }
    return null;
  },
  getValueCurrency(value: string) {
    const result = value.replace('$ ', '').replace('$', '').replaceAll(',', '')
    return Number(result) || 0
  },
  getDaysDiff (startDate: string, endDate: string) {
    const timeStart = moment(startDate);
    const timeEnd = moment(endDate);
    const diff = timeEnd.diff(timeStart);
    const diffDuration = moment.duration(diff);
    return diffDuration.days()
  },
  formatPhoneNumber(phone: string) {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phone;
  },
  formartDateTime (date: string, format = 'YYYY/MM/DD') {
    return moment(date).format(format)
  },
  formartDateTimeWithChat(date: string, format = 'MMM DD, YYYY', formatTime = 'hh:mm A') {
    const now = moment()
    const datetime = moment(date)
    if (now.format('YYYY-MM-DD') === datetime.format('YYYY-MM-DD')) {
      return window.$t('today') + ' ' + window.$t('at') + ' ' + datetime.format(formatTime)
    }
    return moment(date).format(format) + ' ' + window.$t('at') + ' ' + moment(date).format(formatTime)
  },
  addDaysForDateTime (date: any, number: number, unit: any = 'days', format: string = 'YYYY/MM/DD') {
    return moment(date).add(number, unit).format(format)
  },
  compareDate (startDate: any, endDate: any) {
    return moment(startDate).isBefore(moment(endDate))
  },
  setCacheLocalStorage(key:string, data: any, expireAmount: number = 5, expireUnit: moment.DurationInputArg2 = 'minutes') {
    const dataStorage = {
      data: [],
      expired: ''
    }
    if (data) {
      dataStorage.data = data;
      dataStorage.expired = moment().add(expireAmount, expireUnit).format('YYYY-MM-DD HH:mm:ss')
      localStorage[key] = JSON.stringify(dataStorage);
    }
  },
  getCacheLocalStorage(key: string) {
    const now = moment();
    let data = null;
    let isExpired = false;
    if (localStorage[key]) {
      const cachedData = JSON.parse(localStorage[key]);
      const expired = moment(cachedData.expired, 'YYYY-MM-DD HH:mm:ss');
      data = cachedData.data;
      if (now.isAfter(expired)) {
        localStorage.removeItem(key);
        isExpired = true;
      }
    } else {
      isExpired = true;
    }
    return {
      expired: isExpired,
      data
    }
  },
  formatNumberCurrency (num: any) {
    const number = num || 0;
    return '$ ' + this.formatCurrency(number);
  },
  formatCurrency (amount: any, decimalCount = 2, decimal = '.', thousands = ',') {
    try {
      const negativeSign = amount < 0 ? '-' : '';
      // eslint-disable-next-line no-param-reassign
      const i:any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
    } catch (e) {
      console.log(e);
    }
  },
  humanFileSize(size: number) {
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return +((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  },
  trimParamsOrInputData (obj: any) {
    if (!obj) {
      return;
    }
    const arrs = Object.keys(obj);
    arrs.forEach((key) => {
      if (typeof obj[key] === 'string' && this.checkValue(obj[key])) {
        obj[key] = obj[key].trim();
      }
      if (typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          if (this.checkValue(obj[key])) {
            for (let i = 0; i < obj[key].length; i++) {
              let item = obj[key][i];
              if (typeof item === 'string' && this.checkValue(item)) {
                item = item.trim();
              } else {
                this.trimParamsOrInputData(item);
              }
            }
          }
        } else {
          if (this.checkValue(obj[key])) {
            this.trimParamsOrInputData(obj[key]);
          }
        }
      }
    });
  },
  getStateName (arrs: []) {
    if (arrs.length === 0) {
      return ''
    }
    const result = []
    for (let i = 0; i < arrs.length; i++) {
      const element = arrs[i];
      const obj = this.getObjectInArrs(element, states, 'code');
      if (obj) {
        result.push(obj.name)
      }
    }
    return result.join(', ')
  },
  scrollToElement(attr: string) {
    const element = document.getElementById(`container-${attr}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  },
  doPreviewFile(url: string) {
    const domain: string = process.env.REACT_APP_DOC_URL || ''
    if (url.indexOf(domain) > -1) {
      window.open(url)
    } else {
      window.open(`${domain}/${url}`)
    }
  },
  async doGetUploadFile (file: File) {
    try {
      const params = {
        file_name: file.name,
        file_type: file.type,
        file_size: file.size
      }
      const result = await window.$api.getData(window.$api_action.BUYER_UPLOAD_DOC_URL, params)
      if (result && result.data) {
        const uploadResult = await window.$api.uploadFile(result.data.upload_url, file)
        if (uploadResult) {
          return result.data
        }
      }
    } catch (error) {
      console.error(`Error function doUploadFile ${error}`)
    }
    return null
  },
  async doUploadFile (approvalLetterFiles: FileUploadInterface[], proofFundFiles: FileUploadInterface[]) {
    const data: any = {
      doc_urls: [],
      fund_proof_doc_urls: []
    }
    let count = 0
    const arrsFile: FileUploadInterface[] = []
    Array.from(approvalLetterFiles).forEach(element => {
      arrsFile.push(element)
    });
    Array.from(proofFundFiles).forEach(element => {
      arrsFile.push(element)
    });
    if (Array.from(arrsFile).length > 0) {
      await Promise.all(
        Array.from(arrsFile).map(async element => {
          const result = await this.doGetUploadFile(element.file)
          const url = window.$common.getValueFromAttr(result, 'key', '')
          if (url) {
            count++
            if (element.type === 'proof_of_funds') {
              data.fund_proof_doc_urls.push(url)
            } else {
              data.doc_urls.push(url)
            }
          }
        })
      ); 
    }
    if (count === arrsFile.length) {
      return data;
    } else {
      window.$common.showErrorMsg(window.$t('msg_upload_documents_fail'))
    }
    return data
  },
  convertJSONToCSV(headers: String[], jsonData: any) {
    let csv = '';
    csv += headers.join(',') + '\n';
    jsonData.forEach(function (row: any) {
      const data = row.join(',');
      csv += data + '\n'
    });
    return csv
  },
  redirectDashboard(navigate: NavigateFunction, role: string) {
    if (role === window.$const.ROLE.BUYER) {
      navigate('/buyer-dashboard')
    } else if (role === window.$const.ROLE.AGENT) {
      navigate('/agent-dashboard')
    } else {
      navigate('/admin-dashboard')
    }
  },
  redirectToWebsite(url: string) {
    window.location.href = url ?? process.env.REACT_APP_WEB_URL
  },
  getOptionalIncludedItems(optional_includes_items: any) {
    const result: string[] = [];
    const attrs = Object.keys(optional_includes_items)
    if (attrs.length > 0) {
      attrs.forEach(attr => {
        if (optional_includes_items[attr]) {
          result.push(optional_includes_items[attr])
        }
      });
    }
    return result;
  }
}

export default Common;