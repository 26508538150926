import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const API = {
  downloadFile: async (url: string, type: string, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        window.$show_loading()
      }
      axios.defaults.headers.common['Accept'] = type;
      window.$common.handleHeaderAPI(axiosInstance)
      const response = await axiosInstance.get(url, { responseType: 'blob' })
      if (response.data) {
        window.$hide_loading()
        return response.data
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error get data: ${error}`)
    }
    window.$hide_loading()
  },
  getData: async (url: string, params: any, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        window.$show_loading()
      }
      window.$common.handleHeaderAPI(axiosInstance)
      window.$common.trimParamsOrInputData(params)
      const response = await axiosInstance.get(url, { params })
      if (response.data) {
        window.$hide_loading()
        return response.data
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error get data: ${error}`)
    }
    window.$hide_loading()
  },
  postData: async (url: string, data: any, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        window.$show_loading()
      }
      window.$common.handleHeaderAPI(axiosInstance)
      window.$common.trimParamsOrInputData(data)
      const response = await axiosInstance.post(url, data)
      if (response.data) {
        window.$hide_loading()
        return response.data
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error post data: ${error}`)
      window.$hide_loading()
      return error
    }
    window.$hide_loading()
  },
  patchData: async (url: string, data: any, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        window.$show_loading()
      }
      window.$common.handleHeaderAPI(axiosInstance)
      window.$common.trimParamsOrInputData(data)
      const response = await axiosInstance.patch(url, data)
      if (response.data) {
        window.$hide_loading()
        return response.data
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error patch data: ${error}`)
    }
    window.$hide_loading()
  },
  putData: async (url: string, data: any, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        window.$show_loading()
      }
      window.$common.handleHeaderAPI(axiosInstance)
      window.$common.trimParamsOrInputData(data)
      const response = await axiosInstance.put(url, data)
      if (response.data) {
        window.$hide_loading()
        return response.data
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error put data: ${error}`)
    }
    window.$hide_loading()
  },
  deleteData: async (url: string, data: any, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        window.$show_loading()
      }
      window.$common.handleHeaderAPI(axiosInstance)
      window.$common.trimParamsOrInputData(data)
      const response = await axiosInstance.delete(url, data)
      if (response.data) {
        window.$hide_loading()
        return response.data
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error delete data: ${error}`)
    }
    window.$hide_loading()
  },
  uploadFile: async (url: string, file: File) => {
    try {
      window.$show_loading()
      const response = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        }
      })
      if (response) {
        return response
      }
    } catch (error: any) {
      window.$common.handleErrorAPI(error)
      console.error(`Error upload file: ${error}`)
    }
    window.$hide_loading()
  },
}

export default API
