// Action Types
export const SET_IS_LOAD_PAGE = 'SET_IS_LOAD_PAGE'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const SET_USER_INFO = 'SET_USER_INFO'

// Action Creator
export const setIsLoadPage = (payload: boolean) => ({ type: SET_IS_LOAD_PAGE, payload })
export const setIsAuthenticated = (payload: boolean) => ({ type: SET_IS_AUTHENTICATED, payload })
export const setUserInfo = (payload: any) => ({ type: SET_USER_INFO, payload })

